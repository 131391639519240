import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'

import config from '../utils/siteConfig'

import Layout from '../components/Layout'

import Hero from '../components/Hero'
import SEO from '../components/SEO'
import { HorizontalCard } from '../components/Card/index'
import { SectionWithMargin } from '../components/StyledComponents'

const About = ({ data, location }) => {
  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  return (
    <div>
      <Layout lightMenu location={location}>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath={slug} customTitle pageSEO />
          <Hero
            mobileHeight="80vh"
            hero={hero}
          />
          <SectionWithMargin
            css={css`
              padding-bottom: 3rem;
            `}
          >
            {
              data.contentfulPage.pageSection.map((section, index) => {
                const isOdd = index % 2 !== 0
                return (
                  <HorizontalCard
                    name={section.title}
                    description={section.body}
                    image={section.image}
                    key={index}
                    height="500px"
                    reversed={!isOdd}
                  />
                )
              })
            }
          </SectionWithMargin>
      </Layout>
    </div>
  )
}

export default About

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    contentfulPage(slug: { eq: "about" }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      hero {
        ...HeroFragment
      }
      pageSection {
        title
        image {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`